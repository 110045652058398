import React from "react"
import { Helmet } from "react-helmet"

/**
 * SEO component using the react-helment module to set all the headers corresponding to the SEO optimizations.
 * @function SEO
 */

const SEO = ({
  title = "",
  description = "",
  keywords = ["Pixel506"],
  language = "",
  route,
  img = "",
  schema,
  metaHTMLContent,
  headScript,
}) => {
  const siteUrl = "https://pixel506.com"

  const organizationSchema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "@id": "#website",
    url: "https://www.pixel506.com/",
    name: "PIXEL506",
    isFamilyFriendly: "true",
    accessMode: ["textual", "visual", "auditory"],
    accessibilityControl: ["fullKeyboardControl", "fullMouseControl"],
    accessibilityHazard: "noSoundHazard",
  }

  // const websiteSchema = {
  //   "@context": "http://schema.org",
  //   "@type": "Organization",
  //   "@id": "#organization",
  //   name: "Pixel506",
  //   url: "https://www.pixel506.com/",
  //   brand: "Pixel506",
  //   slogan: "Think, Create, Grow",
  //   founder: {
  //     "@type": "Person",
  //     name: "Antonio Ramirez",
  //   },
  //   email: "info@pixel506.com",
  //   sameAs: [
  //     "https:www.facebook.com/PIXEL506/",
  //     "https:www.instagram.com/pixel506official/",
  //     "https:www.linkedin.com/m/login/",
  //     "https:www.youtube.com/channel/UCGaR5CY3HA_2CcGkhYbZQ5Q",
  //     "https:twitter.com/Pixel506",
  //   ],
  // }
  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Pixel506 a 10Peals Company",
    legalName: "Pixel506 a 10Peals Company",
    url: "https://pixel506.com/",
    logo:
      "https://800c8044-cdn.agilitycms.cloud/Pixel_10Pearls Company_Logo.svg",
    foundingDate: "2008",
    founders: [
      {
        "@type": "Person",
        name: "Jessica Saavedra - Antonio Ramirez",
      },
      {
        "@type": "Person",
        name: "",
      },
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: "85 Broad street, New York, NY, United States, New York",
      addressLocality: "New York",
      addressRegion: "NY",
      postalCode: "33444",
      addressCountry: "USA",
    },
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer support",
      telephone: "[+1 646-284-6828]",
      email: "contact@pixel506.com",
    },
    sameAs: [
      "https://www.facebook.com/PIXEL506/",
      "https://www.instagram.com/pixel506official/",
      "https://www.youtube.com/channel/UCGaR5CY3HA_2CcGkhYbZQ5Q/featured",
      "https://twitter.com/Pixel506",
      "https://www.linkedin.com/company/pixel506",
    ],
  }

  return (
    <Helmet
      htmlAttributes={{ lang: language }}
      title={`${title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `og:locale`,
          content: language,
        },
        {
          name: `og:title`,
          content: title,
        },
        {
          name: `og:type`,
          content: "website",
        },
        {
          name: `og:description`,
          content: description,
        },
        {
          name: `og:url`,
          content: `${siteUrl}${route.pathname}`,
        },
        {
          name: `og:site_name`,
          content: "Pixel506",
        },
        {
          name: `og:image`,
          content: img,
        },
        {
          name: `twitter:title`,
          content: "PIXEL506 - a Design Thinking and Digital Branding Co.",
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:site`,
          content: "@Pixel506",
        },
        {
          name: `twitter:creator`,
          content: "@Pixel506",
        },
        {
          name: `twitter:image`,
          content: img,
        },
        // {
        //   name: `robots`,
        //   content: headScript ? headScript : "",
        // },
      ]}
    >

      <link
        rel="preconnect"
        href="https://800c8044-cdn.agilitycms.cloud/"
        crossOrigin
      />
      <link rel="canonical" href={`${siteUrl}${route.pathname}`} />
      <link href="https://bid.g.doubleclick.net" rel="preconnect" />
      <link href="https://www.google-analytics.com" rel="preconnect" />
      <link
        href="https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700;800&display=swap"
        rel="prefetch"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700;800&display=swap"
        rel="stylesheet"
      />
      <link rel="preload" as="style" href={`/fonts/bodoni.css`} />
      <link href={`/fonts/bodoni.css`} rel="stylesheet" />
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(websiteSchema)}
      </script>
      {schema ? (
        // <script type="application/ld+json">{JSON.stringify(schema)}</script>
        <script type="application/ld+json">{schema}</script>
      ) : (
        ""
      )}
    </Helmet>
  )
}
 
export default SEO
